import { toast } from 'react-toastify';
import { debounce } from 'lib/util';

const errorToastDebounceTimeout = parseInt(
  process.env.NEXT_PUBLIC_ERROR_TOAST_DEBOUNCE_TIMEOUT_MS || '5000',
  10
);

export const errorToast = (message: string) => toast.error(message);

export const successToast = (message: string) => toast.success(message);

export const didntWorkToast = () => toast.error("Cela n'a pas fonctionné.");

export const invalidInputToast = () =>
  toast.error('Les informations entrées sont invalides.');

export const wrongCredentialsToast = () =>
  toast.error('Identifiants incorrects.');

export const wrongSignupTokenToast = () =>
  toast.error("Code d'invitation invalide.");

export const joinedClasseToast = (name) =>
  toast.success(`Tu as bien rejoint la classe ${name}.`);

export const networkErrorToast = debounce(
  () => toast.error('Une erreur réseau est survenue.'),
  errorToastDebounceTimeout,
  true
);

export const serverErrorToast = debounce(
  () => toast.error('Une erreur serveur est survenue.'),
  errorToastDebounceTimeout,
  true
);

export const expiredLinkToast = () => toast.error('Ce lien est expiré.');

export const emailAdreadyUsedToast = () =>
  toast.error('Cette adresse email est déjà utilisée.');

export const userNotFoundToast = () =>
  toast.error(
    "Nous n'avons pas trouvé d'utilisateur pour cette adresse email."
  );

export const emailVerifiedToast = () =>
  toast.success('Ton adresse email a été validée.');

export const resentVerificationEmailToast = () =>
  toast.success('Un nouveau lien de validation a été envoyé par email.');

export const profAlreadyHasClasseToast = () =>
  toast.error('Vous avez déjà une classe.');

export const classeCreatedToast = () =>
  toast.success('La classe a bien été créée.');

export const classeDeletedToast = () =>
  toast.success('La classe a bien été supprimée.');

export const classeQuitToast = () =>
  toast.success('Tu as bien quitté la classe.');

export const reportSuccessToast = () =>
  toast.success('Nous avons bien enregistré ton retour.');

export const importElevesSuccessToast = () =>
  toast.success('Les élèves ont bien été ajoutés à la classe.');

export const profNotFoundForEmailToast = () =>
  toast.error("Auncun professeur n'a été trouvé pour cette adresse email.");

export const userIsNotProfToast = () =>
  toast.error("Cet utilisateur n'est pas un professeur.");
