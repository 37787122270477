import * as Sentry from '@sentry/nextjs';

import { ERROR_CODE_SIMPLE_SCHEMA_VALIDATION } from '@inspire/data/static/error-codes';

import { didntWorkToast, invalidInputToast } from 'lib/toasts';

const logError = (error) => {
  process.env.NODE_ENV === 'development' && console.error('Error', error);
  Sentry.captureException(
    new Error(`${error.code}${error.message ? `: ${error.message}` : ''}`)
  );
};

export interface SimpleAPIError {
  code: string;
  message?: string;
  displayMessage?: string;
}

type ErrorHandlingPair = [
  errorCode: string,
  errorHandler: (error: SimpleAPIError) => any
];

export const handleErrors = (
  errors,
  ...errorHandlingPairs: ErrorHandlingPair[]
) => {
  if (!errors) return;

  let errorsHandled = 0;
  errors.forEach((error) => {
    errorHandlingPairs.forEach((handlingPair) => {
      if (handlingPair[0] === error.code || handlingPair[0] === error.message) {
        logError(error);
        handlingPair[1](error);
        errorsHandled++;
      }
    });
    if (error.code === ERROR_CODE_SIMPLE_SCHEMA_VALIDATION) {
      logError(error);
      invalidInputToast();
      errorsHandled++;
    }
  });
  // If no custom error handling was found, show a generic error
  if (errorsHandled === 0) {
    errors.forEach((error) => logError(error));
    didntWorkToast();
  }
};
