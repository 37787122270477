import ProfTips from 'components/activites/ProfTips';
import { useEffect } from 'react';
import {
  Alert,
  Box,
  FormControl,
  List,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import {
  CheckboxGame,
  CustomAgroMixedGame,
  CustomAgroRadioGame,
} from 'components/activites/iterative-games';
import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';
import InfographieChiffresClesSrc from '../../../../../public/img/infographies/chiffres-cles-agro.png';
import ClickableImage from 'components/general/ClickableImage';
import { useMyProfile, useStepContext } from 'lib/hooks';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { InputControl } from 'formik-chakra-ui';
import { RecapList } from 'components/activites/recap-helpers';

const LabelQuestion3Mots =
  'Quels sont les 3 premiers mots auxquels tu penses quand on te parle du secteur agricole ?';

export const SecteurAgricoleEn3Mots = ({ allowNext }) => {
  const { goNext } = useStepContext();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, { onSuccess: goNext });

  const mots = myProfile?.agroSectorActivity1?.mots || [];

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      mot1: mots[0] || '',
      mot2: mots[1] || '',
      mot3: mots[2] || '',
    },
    onSubmit: (values) => {
      setAndRefetchMyProfile({
        agroSectorActivity1: {
          mots: [values?.mot1, values?.mot2, values?.mot3],
        },
      });
    },
  };

  return (
    <>
      <ProfTips mb={3}>
        <Text>
          <b>Conseil d’animation :</b>
        </Text>
        <Text>
          Si vous êtes en classe, vous pouvez aussi faire cette activité à
          l’oral en notant les mots de vos élèves au tableau ou en leur
          demandant de les écrire sur des papiers (pour garder l’anonymat) .
          Affichez ensuite les mots proposés par chatGPT (à la page suivante),
          et demandez à vous élèves s’ils sont surpris ou non, s’ils sont
          d’accord, pourquoi…
        </Text>
        <Text>
          Si vous hésitez entre plusieurs mots, choisissez ceux qui peuvent
          susciter le débat. Demandez aux élèves s’ils sont surpris par le
          résultat, s’ils sont d’accord et pourquoi. Cette activité permet de
          prendre la température en termes d’idées reçues que les élèves
          pourraient avoir, et de lancer la réflexion à ce sujet.
        </Text>
        <Text>
          NB : ces mots ont été générés via ChatGPT, car cet outil permet de
          mettre en lumière les stéréotypes et préjugés. Ils ne font pas office
          de source issue de la recherche.
        </Text>
      </ProfTips>
      <Text mb={10}>
        <b>{LabelQuestion3Mots}</b>
      </Text>
      <Formik {...formik}>
        {() => (
          <Form>
            <FormControl>
              <InputControl
                label="Premier mot"
                name="mot1"
                isRequired
                placeholder="Tape ta réponce ici..."
                mb={5}
              />
              <InputControl
                label="Deuxième mot"
                name="mot2"
                isRequired
                placeholder="Tape ta réponce ici..."
                mb={5}
              />
              <InputControl
                label="Troisième mot"
                name="mot3"
                isRequired
                placeholder="Tape ta réponce ici..."
                mb={5}
              />
            </FormControl>
            <FormActiviteFooter
              isLoading={isSettingAndRefetchingMyProfile}
              allowNext={allowNext}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export const SecteurAgricoleEn3IdeesRecues = () => {
  return (
    <>
      <Alert padding={0} pr={5}>
        <List>
          <Text>
            Quand on pose la question à d’autres lycéens, les réponses les plus
            courantes sont :
          </Text>
          <UnorderedList>
            <ListItem>
              <b>Travail pénible :</b> ils imaginent d’abord que c’est un
              travail d’extérieur, très physique, qui se résume à la culture et
              l’élevage de manière traditionnelle, et ne pensent pas tout de
              suite à une industrie moderne et développée.
            </ListItem>
            <ListItem>
              <b>Campagne :</b> beaucoup de jeunes de ton âge pensent que
              travailler dans le secteur agricole signifie travailler en zone
              rurale. Or, cela réduit encore une fois ce secteur au cliché de la
              production.
            </ListItem>
            <ListItem>
              <b>Peu de technologie :</b> certains peuvent avoir une image du
              secteur agricole comme un secteur qui n’utilise pas ou peu la
              technologie. Pourtant, selon les filières, on y utilise des
              machines qui requièrent une innovation technologique à la pointe,
              et en lien avec les besoins (GPS, drones, capteurs de précision).
            </ListItem>
          </UnorderedList>
          <Text>
            <b>Es-tu surpris ? D’accord ? Pas d’accord ?</b>
          </Text>
        </List>
      </Alert>
    </>
  );
};

export const VraiFauxMondeAgro = ({ allowNext }) => {
  const questions = [
    {
      text: 'La France est le premier producteur agricole en Europe.',
      answerValue: 'true',
      info: 'Avec 18% de la production européenne, la France est en tête aussi bien pour la production animale que végétale. ',
    },
    {
      text: 'L’enseignement agricole, c’est une voie limitée.',
      answerValue: 'false',
      info: "Du CAPA (CAP agricole) au diplôme d'ingénieur (bac + 5), les formations agricoles préparent à tous les domaines de l'agriculture, des cultures à l'élevage en passant par la forêt, l'agroéquipement, les services ou le commerce. De quoi se former à devenir technicien forestier, chef de silo, conducteur d'engins agricoles, vétérinaire, viticulteur, conseiller agricole, maraîcher, chercheur en agronomie, paysagiste, élagueur… Des formations tout au long de la vie permettent également de continuer à se spécialiser ou de se former à d’autres choses.",
    },
    {
      text: 'Le secteur agricole, c’est majoritairement de la production.  ',
      answerValue: 'false',
      info: 'Il existe 4  catégories dans le secteur agricole, et c’est celle des Services qui représente la part la plus importante des emplois (41%), suivie par les métiers de la Production (36%), de l’Aménagement des espaces (19%) et de la Transformation (4%). L’enseignement agricole forme à plus de 200 métiers.',
    },
    {
      text: 'Le secteur agricole c’est le plus souvent une affaire de famille. ',
      answerValue: 'false',
      info: 'Même si de nombreuses exploitations sont tenues par des familles, les enfants d’agriculteurs ne reprennent pas nécessairement les exploitations de leurs parents. Avec les départs à la retraite d’une grande partie des exploitants dans les dix prochaines années, il y a des opportunités de reprise. L’activité salariée se développe aussi que ce soit dans la production ou dans les services et représente environ 700 000 postes. De plus, des aides financières sont proposées par l’Etat pour accompagner l’installation des nouveaux exploitants. Enfin, dans les lycées agricoles, seuls 10% des élèves ont des parents agriculteurs ou salariés agricoles.',
    },
    {
      text: 'L’agriculture, c’est un secteur pénible.',
      answerValue: 'true',
      info: 'En effet, les métiers de la production peuvent être contraignants. Généralement, un agriculteur ne partira pas en vacances en été et à l’automne au moment des moissons, mais bénéficiera d’une période plus calme l’hiver venu. Pour l’élevage, il existe des services de remplacement permettant une présence quotidienne auprès des animaux. D’autre part, les évolutions techniques permettent de pallier la pénibilité de certaines tâches physiques. ',
      customAnswer: 'Vrai et Faux',
    },
    {
      text: 'Les femmes sont moins nombreuses dans le secteur agricole. ',
      answerValue: 'true',
      info: 'Pendant longtemps le travail fourni par les femmes dans le secteur agricole n’était pas reconnu. Aujourd’hui, elles représentent tout de même un tiers des exploitants agricoles, et 40% des salariés du secteur. Dans les prochaines années, elles seront sûrement encore plus présentes, puisque dans l’enseignement agricole, 37% de femmes se forment aux filières de production, alors qu’elles n’étaient que 10% il y a quarante ans !',
      customAnswer: 'VRAI, mais ... ',
    },
  ];

  const options = [
    {
      value: 'true',
      shortName: 'Vrai',
      fullName: 'Vrai',
    },
    {
      value: 'false',
      shortName: 'Faux',
      fullName: 'Faux',
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <>
      <CustomAgroRadioGame
        isVertical={true}
        questions={questions}
        getOptions={() => options}
        onFinished={() => allowNext(true)}
        introSection={
          <Box textAlign={'center'}>
            <Text mb={8}>
              Avec ce quiz, teste tes connaissances sur le monde agricole
            </Text>
          </Box>
        }
        getQuestionSection={(question) => (
          <Text mt={0}>
            <i>{question?.text}</i>
          </Text>
        )}
        getSummarySection={(_, questionIndex) =>
          questionIndex !== 0 && (
            <SummaryFrame mt={8} label="Informations">
              <UnorderedList>
                <ListItem key={`question-agro-${questionIndex}`} mb={5}>
                  <i>{questions[questionIndex - 1]?.text}</i>
                  <Text mt={2}>
                    <b>
                      {questions[questionIndex - 1].customAnswer
                        ? questions[questionIndex - 1].customAnswer
                        : options.find(
                            (c) =>
                              c.value ===
                              questions[questionIndex - 1]?.answerValue
                          )?.fullName}
                      :{' '}
                    </b>
                    {questions[questionIndex - 1]?.info}
                  </Text>
                </ListItem>
              </UnorderedList>
            </SummaryFrame>
          )
        }
      />
    </>
  );
};

export const SecteurAgro4Chiffres = () => {
  return (
    <>
      <Text>
        Des idées reçues, on en a tous et c’est normal. Ca vient d’un mécanisme
        automatique dans notre cerveau qui nous permet de catégoriser ce qui est
        familier de ce qui ne l’est pas. Rien de grave ! Par contre, ce serait
        dommage de s’arrêter à ses idées préconçues et simplistes quand il
        s’agit de faire des choix pour son avenir professionnel ! Allons
        explorer plus en détail les enjeux du secteur agricole et les métiers
        d’avenir !
      </Text>
      <Box maxWidth="500px" margin="auto">
        <ClickableImage
          src={InfographieChiffresClesSrc}
          href="/img/infographies/chiffres-cles-agro.png"
          alt="Infographie Le point sur l'alternance"
        />
      </Box>
    </>
  );
};

export const EnjeuxEtSecteursAvenir = ({ allowNext }) => {
  const questions = [
    {
      text: 'Quel pourcentage de la surface agricole représente la production bio ?',
      answerValues: ['11'],
      displayAnswer: 'La production bio représente 11% de la surface agricole ',
      extraInfo:
        'Il y a eu un vrai effort de la part des exploitants pour développer l’agriculture biologique, les labels de qualité et la vente en circuits courts en France. L’évolution de cette agriculture est tout de même soumise à de fortes contraintes en termes de qualité et de prix. Il existe aussi des productions non-bio qui font des efforts sur les conditions d’élevage et de cultures.',
      inputType: 'radio',
      options: [
        {
          fullName: '3%',
          shortName: '3%',
          value: '3',
        },
        {
          fullName: '11%',
          shortName: '11%',
          value: '11',
        },
        {
          fullName: '19%',
          shortName: '19%',
          value: '19',
        },
      ],
    },
    {
      text: 'Quel niveau de formation est le plus demandé sur le marché de l’emploi agricole ?',
      answerValues: ['bac+2'],
      displayAnswer:
        'Le niveau de formation le plus demandé sur le marché de l’emploi agricole est Bac +2',
      extraInfo:
        'Toute filière confondue, les mutations du secteur agricole nécessitent du personnel de plus de plus qualifié. Par exemple, dans l’Agroéquipement, on recrute du CAP au Diplôme d’ingénieur, mais 57% des employeurs demandent au minimum un BTS pour postuler. Poursuivre ses études après un bac pro permet donc d’élargir ses opportunités d’évolutions, notamment car le développement des nouvelles technologies (numérique, robotique, données) influence les attendus en termes de compétences sur les différents métiers. ',
      inputType: 'radio',
      options: [
        {
          fullName: 'Bac',
          shortName: 'Bac',
          value: 'bac',
        },
        {
          fullName: 'Bac +2',
          shortName: 'Bac +2',
          value: 'bac+2',
        },
        {
          fullName: 'Bac +5',
          shortName: 'Bac +5',
          value: 'bac+5',
        },
      ],
    },
    {
      text: 'Quelles sont les 3 filières du secteur agricole qui vont le plus se développer dans les 10 prochaines années ?  ',
      answerValues: ['agrotechnologie-agroecologie-transformation-alimentaire'],
      displayAnswer:
        'Les 3 filières qui vont le plus se développer dans les 10 prochaines années sont : L’agrotechnologie, l’agroécologie et la transformation alimentaire.',
      extraInfo: (
        <>
          Cela pourrait inclure des postes dans le développement et la
          maintenance de technologies agricoles et alimentaires, la production
          alimentaire durable, la gestion de données agricoles, la distribution
          alimentaire, la logistique, le marketing alimentaire numérique, et
          bien d'autres domaines.
          <br />
          Ces filières sont susceptibles de recruter davantage dans les années à
          venir en raison de la demande croissante pour des solutions
          innovantes, durables et respectueuses de l'environnement dans le
          secteur agricole et alimentaire.
        </>
      ),
      inputType: 'radio',
      options: [
        {
          fullName:
            'Sélection génétique (sélection et développement de nouvelles variétés végétales), agrotourisme (activités touristiques liées à la ferme : visites, hébergement…), et viticulture (culture de la vigne et production du vin)',
          shortName: (
            <>
              <b>Sélection génétique</b> (sélection et développement de
              nouvelles variétés végétales), <b>agrotourisme</b> (activités
              touristiques liées à la ferme : visites, hébergement…), et{' '}
              <b>viticulture</b> (culture de la vigne et production du vin)
            </>
          ),
          value: 'selection-genetique-agrotourisme-viticulture',
        },
        {
          fullName:
            'Horticulture urbaine (production de fruits, légumes et plantes en milieu urbain)  ; apiculture (élevage d’abeilles et production de miel et dérivés) ; et agrotechnologie (développement de solutions technologiques pour l’agriculture',
          shortName: (
            <>
              <b>Horticulture urbaine</b> (production de fruits, légumes et
              plantes en milieu urbain) ; <b>apiculture</b> (élevage d’abeilles
              et production de miel et dérivés) ; et <b>agrotechnologie</b>{' '}
              (développement de solutions technologiques pour l’agriculture)
            </>
          ),
          value: 'horticulture-urbaine-apiculture-agrotechnologie',
        },
        {
          fullName:
            'Agrotechnologie (développement de solutions technologiques pour l’agriculture) ; agroécologie (développement de pratiques de techniques respectueuses de l’environnement), et transformation alimentaire (innovation dans la transformation et la conservation des aliments)',
          shortName: (
            <>
              <b>Agrotechnologie</b> (développement de solutions technologiques
              pour l’agriculture) ; <b>agroécologie</b> (développement de
              pratiques de techniques respectueuses de l’environnement), et{' '}
              <b>transformation alimentaire</b> (innovation dans la
              transformation et la conservation des aliments)
            </>
          ),
          value: 'agrotechnologie-agroecologie-transformation-alimentaire',
        },
      ],
    },
    {
      text: 'Est-il nécessaire d’acheter des terres pour se lancer dans l’exploitation agricole ? ',
      answerValues: ['non'],
      displayAnswer:
        'Il n’est pas nécessaire d’acheter des terres pour se lancer dans l’exploitation agricole',
      extraInfo:
        'La plupart des agriculteurs qui commencent ne sont pas propriétaires des terres qu’ils exploitent. En plus du travail salarié, qui explose dans le secteur agricole, près de 60% des exploitations sont gérées par des locataires. Enfin, avec le défi du renouvellement des générations, de nombreux exploitants sur le départ pour la retraite seront à la recherche de jeunes pour reprendre leur exploitation. ',
      inputType: 'radio',
      options: [
        {
          fullName: 'Oui',
          shortName: 'Oui',
          value: 'oui',
        },
        {
          fullName: 'Non',
          shortName: 'Non',
          value: 'non',
        },
        {
          fullName: 'Ça dépend',
          shortName: 'Ça dépend',
          value: 'depend',
        },
      ],
    },
    {
      text: "Pourquoi les métiers de la transformation agricole sont-ils importants pour l'avenir de l'agriculture (plusieurs réponses possibles) ?",
      answerValues: ['transformer-recoltes', 'garantir-qualite'],
      displayAnswer:
        "Pourquoi Les métiers de la transformation agricole sont-ils importants pour l'avenir de l'agriculture ?",
      extraInfo:
        'Les métiers de la transformation agricole sont cruciaux pour assurer la qualité et la sécurité des aliments transformés que nous consommons, ce qui est essentiel pour la santé publique et la confiance des consommateurs dans les produits alimentaires.',
      inputType: 'checkbox',
      options: [
        {
          fullName:
            'Pour maximiser les rendements des cultures dans les exploitations agricoles.',
          shortName:
            'Pour maximiser les rendements des cultures dans les exploitations agricoles.',
          value: 'maximiser-rendements',
        },
        {
          fullName:
            'Pour transformer les récoltes en produits alimentaires que nous consommons, répondant ainsi à nos besoins alimentaires variés',
          shortName:
            'Pour transformer les récoltes en produits alimentaires que nous consommons, répondant ainsi à nos besoins alimentaires variés',
          value: 'transformer-recoltes',
        },
        {
          fullName:
            'Pour garantir la qualité et la sécurité des aliments transformés pour les consommateurs.',
          shortName:
            'Pour garantir la qualité et la sécurité des aliments transformés pour les consommateurs.',
          value: 'garantir-qualite',
        },
        {
          fullName: 'Pour développer de nouveaux engrais ',
          shortName: 'Pour développer de nouveaux engrais ',
          value: 'developper-engrais',
        },
      ],
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <>
      <CustomAgroMixedGame
        introSection={
          <Box textAlign="center" mt={3}>
            <Text mt={3}>
              Découvre à travers ce quizz, comment les métiers vont être
              influencés par les enjeux liés à l’environnement, les nouvelles
              technologies ou le renouvellement des générations sur les
              prochaines années.
            </Text>
          </Box>
        }
        isVertical
        questions={questions}
        onFinished={() => allowNext(true)}
        getQuestionSection={(question) => (
          <Text mt={0}>
            <b>{question.text}</b>
          </Text>
        )}
        getOptions={(question) => question.options ?? []}
        getSummarySection={(_, questionIndex) =>
          questionIndex !== 0 && (
            <SummaryFrame mt={8} label="Informations">
              <UnorderedList>
                <ListItem key={`question-agro-${questionIndex}`} mb={5}>
                  <b>{questions[questionIndex - 1]?.displayAnswer}</b>
                  <br />
                  {questions[questionIndex - 1]?.extraInfo}
                </ListItem>
              </UnorderedList>
            </SummaryFrame>
          )
        }
      />
    </>
  );
};

export const CategoriesDefis = ({ allowNext, goNext }) => {
  const questions = [
    {
      subject: 'Aménagement et espaces',
      answerValues: [
        'environnement-et-developpement-durable',
        'territoire-et-amenagement',
      ],
    },
    {
      subject: 'Production',
      answerValues: [
        'environnement-et-developpement-durable',
        'nouvelles-technologies',
        'renouvellement-des-generations-et-entrepreneuriat',
      ],
    },
    {
      subject: 'Transformation',
      answerValues: ['nouvelles-technologies'],
    },
    {
      subject: 'Services',
      answerValues: [
        'environnement-et-developpement-durable',
        'territoire-et-amenagement',
        'renouvellement-des-generations-et-entrepreneuriat',
      ],
    },
  ];

  const options = [
    {
      value: 'environnement-et-developpement-durable',
      shortName: 'Environnement et développement durable',
      fullName: 'Environnement et développement durable',
    },
    {
      value: 'nouvelles-technologies',
      shortName: 'Nouvelles technologies',
      fullName: 'Nouvelles technologies',
    },
    {
      value: 'territoire-et-amenagement',
      shortName: 'Territoire et aménagement',
      fullName: 'Territoire et aménagement',
    },
    {
      value: 'renouvellement-des-generations-et-entrepreneuriat',
      shortName: 'Renouvellement des génération et entrepreneuriat',
      fullName: 'Renouvellement des génération et entrepreneuriat',
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <CheckboxGame
      questions={questions}
      getOptions={() => options}
      onFinished={() => {
        allowNext(true);
        goNext();
      }}
      infoSection={
        <Box>
          <Text>
            <b>
              Environnement, territoires, renouvellement des générations,
              nouvelles technologies… Que de défis pour le secteur agricole à
              relever !
            </b>
          </Text>
          <Text textAlign="center">
            <i>
              Associe à chaque filière du secteur agricole un ou plusieurs
              enjeux futurs qui peuvent le concerner :
            </i>
          </Text>
        </Box>
      }
      getQuestionSection={(question) => (
        <Text>
          <b>{question.subject}</b>
        </Text>
      )}
    />
  );
};

export const RecapJeMeRepereEtJeMinforme = ({ profile }) => {
  return (
    <RecapList
      profile={profile}
      fieldName={'agroSectorActivity1.mots'}
      label={LabelQuestion3Mots}
    />
  );
};
