import { useSession } from 'next-auth/client';
import { isBoolean } from 'lodash';

import {
  ClasseProfsQuery,
  CvdesignrFlowQuery,
  IsHorsScopeQuery,
  ProfByEmailQuery,
  SetStateOfMindMutation,
  StateOfMindQuery,
  useClasseProfsQuery,
  useCurrentClasseForLyceenQuery,
  useCurrentClasseForProfQuery,
  useCvdesignrFlowQuery,
  useIsHorsScopeQuery,
  useProfByEmailQuery,
  useStateOfMindQuery,
} from 'lib/generated';

import {
  ROLE_LYCEEN,
  ROLE_ECLAIREUR,
  ROLE_PROF,
} from '@inspire/data/static/roles';
import { hasModules, isAgro, isGatlRc } from '@inspire/data/helpers/classe';

import {
  useQuery,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from 'react-query';
import { handleErrors } from 'lib/errors';
import {
  ClasseByTokenQuery,
  EclaireursQuery,
  EleveQuery,
  ElevesActivitesBacProQuery,
  FaqItemsQuery,
  LyceesQuery,
  MyBacProActivitesQuery,
  MyProfileQuery,
  PisteBySlugQuery,
  useClasseByTokenQuery,
  useEclaireursQuery,
  useEleveQuery,
  useElevesActivitesBacProQuery,
  useFaqItemsQuery,
  useLyceesQuery,
  useMyBacProActivitesQuery,
  useMyProfileQuery,
  usePistesQuery,
  usePisteBySlugQuery,
  PistesQuery,
  useEclaireurQuery,
  EclaireurQuery,
  AllClassesQuery,
  useAllClassesQuery,
  GitInfoQuery,
  useGitInfoQuery,
  MyContactRequestsQuery,
  useMyContactRequestsQuery,
  useChatThreadByIdQuery,
  ChatThreadByIdQuery,
  useArticlesQuery,
  ArticlesQuery,
  PisteByIdQuery,
  usePisteByIdQuery,
  useFormationsQuery,
  FormationsQuery,
  EtablissementsQuery,
  useEtablissementsQuery,
  ClasseByIdQuery,
  useClasseByIdQuery,
  useLyceenQuery,
  LyceenQuery,
  SetMyProfileMutation,
} from 'lib/generated';
import { getSearchClient, SearchIndex, SearchResults } from 'lib/search';

import type {
  MutationOptions,
  QueryOptions,
} from 'lib/hooks/react-query-types';
import { useSetMyProfile, useSetStateOfMind } from 'lib/hooks/mutations';
import { isGatl, isRc } from '@inspire/data/helpers/user';

export const useUser = () => {
  const [session, isUserLoading] = useSession();
  const user = session?.user;

  return {
    session,
    user,
    isUserLoading,
    isLyceen: user?.roles?.includes(ROLE_LYCEEN), // @Todo : Enquete : pas de roles dans le type User
    isEclaireur: user?.roles?.includes(ROLE_ECLAIREUR),
    isProf: user?.roles?.includes(ROLE_PROF),
    ssoId: user?.ssoId,
    newsletter: user?.profile?.newsletter,
    pedagogieFiliereGenerale: user?.profile?.pedagogieFiliereGenerale,
    pedagogieFiliereTechnologique: user?.profile?.pedagogieFiliereTechnologique,
    pedagogieFiliereProfessionnelle:
      user?.profile?.pedagogieFiliereProfessionnelle,
  };
};

// Provides a convenient combination of useMyProfile and useSetMyProfile
// with a default refetching behavior and default error handling
export const useMyProfile = (
  options?: QueryOptions<MyProfileQuery>,
  setterOptions?: MutationOptions<SetMyProfileMutation>
) => {
  const { data, isLoading, refetch, ...rest } = useMyProfileQuery(
    null,
    options
  );

  const {
    setMyProfile: setAndRefetchMyProfile,
    isSettingMyProfile: isSettingAndRefetchingMyProfile,
    ...setter
  } = useSetMyProfile({
    ...setterOptions,
    onSuccess: async (successData, variables, context) => {
      await refetch();
      if (setterOptions?.onSuccess) {
        await setterOptions.onSuccess(successData, variables, context);
      }
    },
    onError: setterOptions?.onError ?? (({ errors }) => handleErrors(errors)),
  });
  return {
    myProfile: {
      ...data?.myUser?.profile,
      isEmailVerified: data?.myUser?.emails?.[0]?.verified,
      piste: data?.myUser?.piste,
      isProfileComplete: data?.myUser?.isProfileComplete,
      algoResults: data?.myUser?.algoResults,
      isQuestionnaireComplete: data?.myUser?.isQuestionnaireComplete,
      phoneNumber: data?.myUser?.phoneNumber,
      email: data?.myUser?.emails?.[0]?.address,
      createdAt: data?.myUser?.createdAt,
      // @ts-ignore : TYPING TODO LATER
      isPostBac: data?.myUser?.profile.niveau === 'Post-bac',
    },
    isMyProfileLoading: isLoading,
    ssoId: data?.myUser?.ssoId,
    refetchMyProfile: refetch,
    setAndRefetchMyProfile,
    isSettingAndRefetchingMyProfile,
    ...rest,
    setter,
  };
};

export const useStateOfMind = (
  options?: QueryOptions<StateOfMindQuery>,
  setterOptions?: MutationOptions<SetStateOfMindMutation>
) => {
  const { data, isLoading, refetch, ...rest } = useStateOfMindQuery(
    null,
    options
  );

  const {
    setStateOfMind: setAndRefetchStateOfMind,
    isSettingStateOfMind: isSettingAndRefetchingStateOfMind,
    ...setter
  } = useSetStateOfMind({
    ...setterOptions,
    onSuccess: async (successData, variables, context) => {
      await refetch();
      if (setterOptions?.onSuccess) {
        await setterOptions.onSuccess(successData, variables, context);
      }
    },
    onError: setterOptions?.onError ?? (({ errors }) => handleErrors(errors)),
  });
  return {
    myStateOfMind: data?.myUser?.stateOfMind,
    isStateOfMindLoading: isLoading,
    refetchStateOfMind: refetch,
    setAndRefetchStateOfMind,
    isSettingAndRefetchingStateOfMind,
    ...rest,
    setter,
  };
};

export const useClasse = (options: QueryOptions<any> = {}) => {
  const { isUserLoading, isProf, isLyceen } = useUser();

  const {
    isLoading,
    data: classeData,
    isFetching,
    refetch,
    ...rest
  } = (isProf ? useCurrentClasseForProfQuery : useCurrentClasseForLyceenQuery)(
    null,
    {
      ...options,
      enabled: !!(
        (options?.enabled ?? true) &&
        !isUserLoading &&
        (isProf || isLyceen)
      ),
    }
  );

  const classe = classeData?.currentClasse;

  return {
    isClasseLoading: isUserLoading || isLoading,
    refetchClasse: refetch,
    isClasseFetching: isFetching,
    classe,
    isSeconde: classe?.niveau === 'seconde',
    isPremiere: classe?.niveau === 'premiere',
    isTerminale: classe?.niveau === 'terminale',
    classeIsGatlRc: isGatlRc(classe),
    classeIsAgro: isAgro(classe),
    classeHasModules: hasModules(classe),
    ...rest,
  };
};

export const useClasses = (options?: QueryOptions<AllClassesQuery>) => {
  const { data, isLoading, refetch, ...rest } = useAllClassesQuery(
    null,
    options
  );
  return {
    classes: data?.allClasses,
    areClassesLoading: isLoading,
    refetchClasses: refetch,
    ...rest,
  };
};

export const useFaqItems = (options?: QueryOptions<FaqItemsQuery>) => {
  const { data, isLoading, ...rest } = useFaqItemsQuery(null, options);
  return { faqItems: data?.faqItems, areFaqItemsLoading: isLoading, ...rest };
};

export const useStudyYear = (
  year: number,
  options?: QueryOptions<MyProfileQuery>
) => {
  const { data, isLoading, refetch, ...rest } = useMyProfileQuery(
    null,
    options
  );
  const studyYear =
    data?.myUser?.profile?.studies?.find((y) => y.year === year) || {};
  const previousYear =
    data?.myUser?.profile?.studies?.find((y) => y.year === year - 1) || {};

  return {
    isLoadingStudyYear: isLoading,
    refetchStudyYear: refetch,
    studyYear,
    previousYear,
    ...rest,
  };
};

export const useContactRequest = (
  params?: { threadId?: string },
  options?: QueryOptions<MyContactRequestsQuery>
) => {
  const { data, isLoading, ...rest } = useMyContactRequestsQuery(
    {
      limit: 1,
      ...params,
    },
    options
  );
  return {
    contactRequest: data?.myContactRequests?.[0],
    isContactRequestLoading: isLoading,
    ...rest,
  };
};

export const useChatThreadById = (
  threadId: string,
  options?: QueryOptions<ChatThreadByIdQuery>
) => {
  const { data, isLoading, ...rest } = useChatThreadByIdQuery(
    { threadId },
    options
  );
  return {
    messages: data?.messagesByThreadId,
    areMessagesLoading: isLoading,
    ...rest,
  };
};

export const useClasseByToken = (
  token: string,
  options?: QueryOptions<ClasseByTokenQuery>
) => {
  const { data, isLoading, ...rest } = useClasseByTokenQuery(
    { signupToken: token },
    options
  );
  return {
    classeByToken: data?.classeByToken,
    isClasseByTokenLoading: isLoading,
    ...rest,
  };
};

export const useClasseById = (
  id: string,
  options?: QueryOptions<ClasseByIdQuery>
) => {
  const { data, isLoading, isRefetching, ...rest } = useClasseByIdQuery(
    { id },
    options
  );
  return {
    classeById: data?.classeById,
    isClasseByIdLoading: isLoading || isRefetching,
    ...rest,
  };
};

export const useEleve = (id: string, options?: QueryOptions<EleveQuery>) => {
  const { data, isLoading, ...rest } = useEleveQuery({ id }, options);
  return { eleve: data?.eleve, isEleveLoading: isLoading, ...rest };
};

export const useLyceen = (id: string, options?: QueryOptions<LyceenQuery>) => {
  const { data, isLoading, ...rest } = useLyceenQuery({ id }, options);
  return { lyceen: data?.eleve, isLyceenLoading: isLoading, ...rest };
};

export const useEclaireur = (
  id: string,
  options?: QueryOptions<EclaireurQuery>
) => {
  const { data, isLoading, ...rest } = useEclaireurQuery({ id }, options);
  return { eclaireur: data?.eclaireur, isEclaireurLoading: isLoading, ...rest };
};

export const useProfByEmail = (
  email: string,
  options?: QueryOptions<ProfByEmailQuery>
) => {
  const { data, isLoading, refetch, isFetching, ...rest } = useProfByEmailQuery(
    { email },
    options
  );
  return {
    profByEmail: data?.profByEmail,
    isProfByEmailLoading: isLoading,
    isProfByEmailFetching: isFetching,
    refetchProfByEmail: refetch,
    ...rest,
  };
};

export const useClasseProfs = (
  classeId: string,
  options?: QueryOptions<ClasseProfsQuery>
) => {
  const { data, isLoading, refetch, isFetching, ...rest } = useClasseProfsQuery(
    { classeId },
    options
  );
  return {
    classeProfs: data?.classeById?.profs,
    areClasseProfsLoading: isLoading,
    refetchClasseProfs: refetch,
    isClasseProfsFetching: isFetching,
    ...rest,
  };
};

export const useLycees = (
  {
    departement,
    hasIntervention,
    isLyceePro,
  }: // codeUAI,
  {
    departement?: string;
    hasIntervention?: boolean;
    isLyceePro?: boolean;
    codeUAI?: string;
  },
  options?: QueryOptions<LyceesQuery>
) => {
  const { data, isLoading, refetch, ...rest } = useLyceesQuery(
    { departement, hasIntervention, isLyceePro },
    options
  );
  return {
    lycees: data?.lycees,
    areLyceesLoading: isLoading,
    refetchLycees: refetch,
    ...rest,
  };
};

export const useFormations = (
  { onisepId }: { onisepId: string },
  options?: QueryOptions<FormationsQuery>
) => {
  const { data, isLoading, refetch, ...rest } = useFormationsQuery(
    { onisepId },
    options
  );
  return {
    formations: data?.formations,
    areFormationsLoading: isLoading,
    refetchFormations: refetch,
    ...rest,
  };
};

export const useEtablissements = (
  { departement }: { departement: string },
  options?: QueryOptions<EtablissementsQuery>
) => {
  const { data, isLoading, refetch, ...rest } = useEtablissementsQuery(
    { departement },
    options
  );
  return {
    etablissements: data?.etablissements,
    areEtablissementsLoading: isLoading,
    refetchEtablissements: refetch,
    ...rest,
  };
};

export const usePistes = (
  filters?: {
    filiere?: string;
    bacs?: string[];
    domaines?: string[];
    isApprentissage?: boolean;
    isInsertionPro?: boolean;
    typesFormation?: string[];
  },
  options?: QueryOptions<PistesQuery>
) => {
  // const { classeHasModules, classe } = useClasse();
  const { myProfile } = useMyProfile();
  // const classeHasModulesOptions = classe
  //   ? classeHasModules
  //   : (filters?.isInsertionPro || (isGatl(myProfile) || isRc(myProfile))) ?? true;
  const classeHasModulesOptions =
    (filters?.isInsertionPro || isGatl(myProfile) || isRc(myProfile)) ?? true;

  const { data, isLoading, refetch, ...rest } = usePistesQuery(
    { ...filters, classeHasModules: classeHasModulesOptions },
    options
  );

  return {
    pistes: data?.pistes,
    arePistesLoading: isLoading,
    refetchPistes: refetch,
    ...rest,
  };
};

export const useArticles = (
  filters?: { skip?: number; limit?: number; slug?: string; tag?: string },
  options?: QueryOptions<ArticlesQuery>
) => {
  const { data, isLoading, refetch, ...rest } = useArticlesQuery(
    filters,
    options
  );
  return {
    articles: data?.articles,
    areArticlesLoading: isLoading,
    refetchArticles: refetch,
    ...rest,
  };
};

export const usePisteBySlug = (
  slug: string,
  options?: QueryOptions<PisteBySlugQuery>
) => {
  const { data, isLoading, refetch, ...rest } = usePisteBySlugQuery(
    { slug },
    options
  );
  return {
    piste: data?.piste,
    isPisteLoading: isLoading,
    refetchPiste: refetch,
    ...rest,
  };
};

export const usePisteById = (
  _id: string,
  options?: QueryOptions<PisteByIdQuery>
) => {
  const { data, isLoading, refetch, ...rest } = usePisteByIdQuery(
    { _id },
    options
  );
  return {
    piste: data?.piste,
    isPisteLoading: isLoading,
    refetchPiste: refetch,
    ...rest,
  };
};

export const useMyBacProActivites = (
  options?: QueryOptions<MyBacProActivitesQuery>
) => {
  const { data, isLoading, isFetching, ...rest } = useMyBacProActivitesQuery(
    null,
    options
  );
  return {
    myBacProActivites: data?.myUser?.activitesBacPro,
    areMyBacProActivitesLoading: isLoading,
    areMyBacProActivitesFetching: isFetching,
    ...rest,
  };
};

export const useEclaireurs = (
  filters?,
  options?: QueryOptions<EclaireursQuery>
) => {
  const { data, isLoading, refetch, ...rest } = useEclaireursQuery(
    { filters },
    options
  );
  return {
    eclaireurs: data?.eclaireurs,
    areEclaireursLoading: isLoading,
    refetchEclaireurs: refetch,
    ...rest,
  };
};

export const useElevesActivitesBacPro = (
  options?: QueryOptions<ElevesActivitesBacProQuery>
) => {
  const { data, isLoading, isFetching, ...rest } =
    useElevesActivitesBacProQuery(null, options);
  return {
    // @ts-ignore
    elevesActivitesBacPro: data?.currentClasse?.eleves,
    areElevesActivitesBacProLoading: isLoading,
    areElevesActivitesBacProFetching: isFetching,
    ...rest,
  };
};

export const useGitInfo = (options?: QueryOptions<GitInfoQuery>) => {
  const { data, ...rest } = useGitInfoQuery(null, options);
  return { gitInfo: data?.gitInfo, ...rest };
};

// useIsQuestionnaireComplete and useIsEclaireurProfileComplete
// return true by default, so that we show the completed UI during
// loading, and for users of other types to be true by default
export const useIsQuestionnaireComplete = () => {
  const { isLyceen, isUserLoading } = useUser();
  const { myProfile, isMyProfileLoading } = useMyProfile();
  const isQuestionnaireComplete = myProfile?.isQuestionnaireComplete;
  return (
    isUserLoading ||
    isMyProfileLoading ||
    !isBoolean(isQuestionnaireComplete) ||
    (isLyceen ? isQuestionnaireComplete : true)
  );
};

export const useIsEclaireurProfileComplete = () => {
  const { isEclaireur, isUserLoading } = useUser();
  const { myProfile, isMyProfileLoading } = useMyProfile();

  return (
    isUserLoading ||
    isMyProfileLoading ||
    !isBoolean(myProfile?.isProfileComplete) ||
    (isEclaireur
      ? myProfile.isProfileComplete && myProfile.isEmailVerified
      : true)
  );
};

// This can actually mutate the backend, so not really a "query", but it's simpler this way
export const useCvdesignrFlow = (
  options?: QueryOptions<CvdesignrFlowQuery>
) => {
  const { data, isLoading, refetch, isRefetching, ...rest } =
    useCvdesignrFlowQuery(null, options);
  return {
    cvdesignrFlow: data?.cvdesignrFlow,
    isCvdesignrFlowLoading: isLoading || isRefetching,
    refetchCvdesignrFlow: refetch,
    ...rest,
  };
};

interface AutcompleteProps {
  query: string;
}

export const useAutocomplete = (props: AutcompleteProps) => {
  const { user } = useUser();
  const { query } = props;
  return useQuery(['autocomplete', query], () =>
    getSearchClient().autocomplete(query, [
      SearchIndex.Pistes,
      SearchIndex.Articles,
      ...(user ? [SearchIndex.Eclaireurs] : []),
    ])
  );
};

interface SearchProps {
  query: string;
}

export const useInfiniteSearchArticles = (props: SearchProps) => {
  const { query } = props;
  return useInfiniteQuery(
    'search-articles',
    async ({ pageParam = 0 }) =>
      getSearchClient().searchForArticles({ query, page: pageParam }),
    {
      getPreviousPageParam: () => undefined,
      getNextPageParam: (d) =>
        d.numberOfPages !== d.page + 1 ? d.page + 1 : undefined,
    }
  );
};

export const useInfiniteSearchPistes = (props: SearchProps) => {
  const { query } = props;
  return useInfiniteQuery(
    'search-pistes',
    async ({ pageParam = 0 }) =>
      getSearchClient().searchForPistes({ query, page: pageParam }),
    {
      getPreviousPageParam: () => undefined,
      getNextPageParam: (d) =>
        d.numberOfPages !== d.page + 1 ? d.page + 1 : undefined,
    }
  );
};

export const useInfiniteSearchEclaireurs = (props: SearchProps) => {
  const { query } = props;
  return useInfiniteQuery(
    'search-eclaireurs',
    async ({ pageParam = 0 }) =>
      getSearchClient().searchForEclaireurs({ query, page: pageParam }),
    {
      getPreviousPageParam: () => undefined,
      getNextPageParam: (d) =>
        d.numberOfPages !== d.page + 1 ? d.page + 1 : undefined,
    }
  );
};

export const useIsHorsScope = (options?: QueryOptions<IsHorsScopeQuery>) => {
  const { data, isLoading } = useIsHorsScopeQuery(null, options);
  return { isHorsScope: data?.isHorsScope, isHorsScopeLoading: isLoading };
};

export type QuerySearchResults<T> = UseInfiniteQueryResult<SearchResults<T>>;
