import TagManager from 'react-gtm-module';

export const loadCrisp = (user) => {
  if (process.env.NEXT_PUBLIC_CRISP_ID) {
    window.$crisp = [['set', 'user:email', user.username]];
    window.CRISP_WEBSITE_ID = process.env.NEXT_PUBLIC_CRISP_ID;
    (() => {
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      // @ts-ignore
      s.async = 1;
      d.getElementsByTagName('body')[0].appendChild(s);
    })();
  }
};

export const loadGTM = () => {
  if (
    process.env.NEXT_PUBLIC_GTM_ID &&
    process.env.NEXT_PUBLIC_GTM_AUTH &&
    process.env.NEXT_PUBLIC_GTM_PREVIEW
  ) {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      auth: process.env.NEXT_PUBLIC_GTM_AUTH,
      preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
    });
  }
};
