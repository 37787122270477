export var PisteTypes = {
    license: "Licence",
    prepa: "Classe Pr\xe9paratoire aux Grandes Ecoles (CPGE)",
    dut: "Bachelor Universitaire de Technologie (BUT)",
    bts: "Brevet de Technicien Sup\xe9rieur (BTS)",
    ecole: "Ecole post-bac",
    deust: "Dipl\xf4me d'Etudes Universitaires Scientifiques et Techniques (DEUST)",
    mentionComplementaire: "Mention Compl\xe9mentaire (MC)",
    formationArtistique: "Formation Artistique",
    man: "Mise \xe0 niveau",
    fcil: "Formation Compl\xe9mentaire d’Initiative Locale (FCIL)"
};
export var EtablissementTypes = {
    lyceeCfa: "Lyc\xe9e ou CFA",
    universite: "Universit\xe9",
    ecole: "\xc9cole"
};
export var EnseignementTypes = {
    classe: 'Proche du format "classe" du lyc\xe9e',
    classeAmphi: "Proche format “classe” avec certains enseignements dispens\xe9s en amphith\xe9\xe2tre",
    amphiTD: "Enseignements dispens\xe9s en amphith\xe9\xe2tre et TD (travaux dirig\xe9s)"
};
export var FormatTypes = {
    pratique: "Pratique avec des stages",
    theorique: "Th\xe9orique",
    theoriqueEtPratique: "Th\xe9orique et pratique avec des stages"
};
export var DureePiste = {
    "6_mois_1_an": "Entre 6 mois et 1 an",
    "1_an": "1 an",
    "2_ans": "2 ans",
    "3_ans": "3 ans"
};
