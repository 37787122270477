import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { StaticHelpers } from "@inspire/data/helpers/static";
import { Bacs, TypesBac, Bacs2019 } from "@inspire/data/static/static";
import { ExploreSchema } from "@inspire/data/schemas/user/profile/explore";
import { BacsPro } from "@inspire/data/static/bacs-pro";
import { ROLE_ADMIN, ROLE_LYCEEN, ROLE_ECLAIREUR } from "@inspire/data/static/roles";
import { sortBy, isEmpty, find, head, filter, reduce, some, range } from "lodash";
import { exists } from "@v1v2/util";
import { getCreatedAtSchoolYear } from "@inspire/data/helpers/static";
import { cdnURL } from "@inspire/util/cdn";
export function userEmail(user) {
    var ref;
    //@ts-ignore
    return (ref = head(user === null || user === void 0 ? void 0 : user.emails)) === null || ref === void 0 ? void 0 : ref.address;
}
export function isEmailVerified(user) {
    return some(user.emails, function(email) {
        return email.verified;
    });
}
export function userFullName(user) {
    var ref, ref1;
    return [
        user === null || user === void 0 ? void 0 : (ref = user.profile) === null || ref === void 0 ? void 0 : ref.firstName,
        user === null || user === void 0 ? void 0 : (ref1 = user.profile) === null || ref1 === void 0 ? void 0 : ref1.lastName
    ].join(" ");
}
export function userStudyYear(year) {
    if (!year) return null;
    var formation = year.formation || "Formation non-pr\xe9cis\xe9e";
    var ville = year.ville || "";
    var departement = StaticHelpers.getDepartmentFromPostalCode(year.codePostal);
    var etablissement = (year === null || year === void 0 ? void 0 : year.etablissement) || "\xc9tablissement non-pr\xe9cis\xe9e";
    var dep = departement ? "(".concat(departement, ")") : "";
    return "".concat(formation, ", ").concat(etablissement, " ").concat(ville ? ", ".concat(ville, " ").concat(dep) : "");
}
export function userStudyYearSecond(secondFormation) {
    if (!secondFormation) return "";
    var formation = (secondFormation === null || secondFormation === void 0 ? void 0 : secondFormation.formation) || "Formation non-pr\xe9cis\xe9e";
    var ville = (secondFormation === null || secondFormation === void 0 ? void 0 : secondFormation.ville) || "";
    var departement = StaticHelpers.getDepartmentFromPostalCode(secondFormation === null || secondFormation === void 0 ? void 0 : secondFormation.codePostal);
    var etablissement = (secondFormation === null || secondFormation === void 0 ? void 0 : secondFormation.etablissement) || "\xc9tablissement non-pr\xe9cis\xe9e";
    var dep = departement ? "(".concat(departement, ")") : "";
    return "".concat(formation, ", ").concat(etablissement, " ").concat(ville ? ", ".concat(ville, " ").concat(dep) : "");
}
export function bacEclaireur(user) {
    var ref;
    var f = user === null || user === void 0 ? void 0 : (ref = user.profile) === null || ref === void 0 ? void 0 : ref.filiere;
    if (!f) {
        return null;
    }
    var listeBacs = _object_spread({}, Bacs, TypesBac);
    return f === "bac_pro" ? "Bac Pro " + (BacsPro[user.profile.filiereBacPro] || "") : "Bac " + listeBacs[f];
}
export function userProfileImage(user) {
    var ref, ref1;
    var picture = user === null || user === void 0 ? void 0 : (ref = user.profile) === null || ref === void 0 ? void 0 : ref.picture;
    var avatar;
    if (!picture && (user === null || user === void 0 ? void 0 : (ref1 = user.profile) === null || ref1 === void 0 ? void 0 : ref1.role) === "eclaireur") {
        var lastChar = user._id && user._id[user._id.length - 1];
        var val = lastChar && (lastChar.charCodeAt(0) % 5 + 1).toString();
        avatar = "/img/avatars/".concat(val, ".png");
    }
    return cdnURL(picture) || avatar || "/img/no-photo.png";
}
export function userCity(user) {
    var ref, ref1;
    if (!isEmpty(user === null || user === void 0 ? void 0 : (ref = user.profile) === null || ref === void 0 ? void 0 : ref.studies) && (user === null || user === void 0 ? void 0 : (ref1 = user.profile) === null || ref1 === void 0 ? void 0 : ref1.role) === "eclaireur") {
        // XX: sort studies by ascending year and return most rescent city
        var year = find(sortBy(user.profile.studies, "year").reverse(), function(y) {
            return (y === null || y === void 0 ? void 0 : y.ville) !== undefined;
        });
        return year === null || year === void 0 ? void 0 : year.ville;
    }
    return null;
}
export function lyceenFiliere(user) {
    var ref;
    if (!(user === null || user === void 0 ? void 0 : user.profile)) {
        return false;
    }
    var filiere = user === null || user === void 0 ? void 0 : (ref = user.profile) === null || ref === void 0 ? void 0 : ref.filiere;
    var filiereName = Bacs[filiere] || Bacs2019[filiere];
    if (filiere === "bac_techno") {
        var ref1;
        return "".concat(filiereName, " ").concat((user === null || user === void 0 ? void 0 : (ref1 = user.profile) === null || ref1 === void 0 ? void 0 : ref1.filiereTechno) || "");
    }
    if (filiere === "bac_pro") {
        var ref2;
        return "Bac Pro ".concat(BacsPro[user === null || user === void 0 ? void 0 : (ref2 = user.profile) === null || ref2 === void 0 ? void 0 : ref2.filiereBacPro] || "");
    }
    return filiereName;
}
export function getLastLyceeStudyYear(userOrProfile) {
    var ref, ref1;
    if (userOrProfile === null || userOrProfile === void 0 ? void 0 : userOrProfile.profile) {
        var ref2, ref3, ref4;
        return (ref4 = userOrProfile === null || userOrProfile === void 0 ? void 0 : (ref2 = userOrProfile.profile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.lyceeStudies) === null || ref3 === void 0 ? void 0 : ref3.sort(function(a, b) {
            return b.annee - a.annee;
        })) === null || ref4 === void 0 ? void 0 : ref4[0];
    }
    return (ref1 = userOrProfile === null || userOrProfile === void 0 ? void 0 : (ref = userOrProfile.lyceeStudies) === null || ref === void 0 ? void 0 : ref.sort(function(a, b) {
        return b.annee - a.annee;
    })) === null || ref1 === void 0 ? void 0 : ref1[0];
}
export function isUserInRole(user, role) {
    var ref;
    return (user === null || user === void 0 ? void 0 : (ref = user.profile) === null || ref === void 0 ? void 0 : ref.role) === role;
}
export function isUserEclaireur(user) {
    return isUserInRole(user, ROLE_ECLAIREUR);
}
export function isUserLyceen(user) {
    return isUserInRole(user, ROLE_LYCEEN);
}
export function isUserAdmin(user) {
    return isUserInRole(user, ROLE_ADMIN);
}
export function isUserPostBac(user) {
    var ref;
    return isUserLyceen(user) && ((ref = getLastLyceeStudyYear(user)) === null || ref === void 0 ? void 0 : ref.niveau) === "Post-bac";
}
// TODO: fix types
export function isExploreComplete(user) {
    var defsWithQuestions = filter(ExploreSchema.schema(), function(def) {
        // @ts-ignore
        return def.question;
    });
    var profile = user.profile;
    return reduce(defsWithQuestions, function(memo, d) {
        // @ts-ignore
        var q = d.question;
        var conditionsMet = q.conditions ? q.conditions(profile) : true;
        return memo && (conditionsMet ? q.isAnswered(profile) : true);
    }, true);
}
// Eclaireurs Helpers
function checkProfileFieldsAreSet(profile, fields) {
    for(var i = 0; i < fields.length; i++){
        if (!exists(profile[fields[i]])) {
            return false;
        }
    }
    return true;
}
// Eclaireurs "Experiences à Partager"
export function isStudentExperienceComplete(profile) {
    var fields = [
        "willReviewCV",
        "apprentissage",
        "livedWithFamilyBacP1",
        "studiedAbroad",
        "knewNobodyBacP1",
        "logementEtudiant", 
    ];
    return checkProfileFieldsAreSet(profile, fields);
}
export function isGeneralInfoComplete(profile) {
    var fields = [
        "birthDate",
        "gender"
    ];
    return checkProfileFieldsAreSet(profile, fields);
}
export function isBacInfoComplete(profile) {
    var fields = [
        "lyceeStudies",
        "departement",
        "lycee",
        // 'boursierLycee',
        "studiesFirstYear", 
    ];
    return checkProfileFieldsAreSet(profile, fields);
}
export function isStudiesComplete(profile) {
    var ref, ref1;
    // Cas Eclaireur qui a été lycéen
    if ((profile === null || profile === void 0 ? void 0 : profile.lyceeId) && (profile === null || profile === void 0 ? void 0 : profile.studies)) {
        var ref2, ref3;
        return ((ref3 = profile === null || profile === void 0 ? void 0 : (ref2 = profile.studies) === null || ref2 === void 0 ? void 0 : ref2.sort(function(a, b) {
            return a.year - b.year;
        })[0]) === null || ref3 === void 0 ? void 0 : ref3.year) === profile.studiesFirstYear;
    }
    return profile.studiesFirstYear && (profile === null || profile === void 0 ? void 0 : (ref = profile.studies) === null || ref === void 0 ? void 0 : ref.length) >= ((ref1 = range(profile.studiesFirstYear, getCreatedAtSchoolYear(profile.createdAt) + 1)) === null || ref1 === void 0 ? void 0 : ref1.length);
}
export function isPisteEtudeComplete(profile) {
    return profile.pisteId !== undefined && profile.pisteId !== null;
}
export function isProfileComplete(user) {
    var profile = user.profile;
    profile.createdAt = user.createdAt;
    return [
        isGeneralInfoComplete,
        isBacInfoComplete,
        isStudiesComplete,
        isStudentExperienceComplete,
        isPisteEtudeComplete, 
    ].reduce(function(prev, curr) {
        return prev && !!curr(profile);
    }, true);
}
export var isRc = function(object) {
    var ref, ref1, ref2;
    return ((ref = getLastLyceeStudyYear(object)) === null || ref === void 0 ? void 0 : ref.filiereBacPro) === "accueil" || ((ref1 = getLastLyceeStudyYear(object)) === null || ref1 === void 0 ? void 0 : ref1.filiereBacPro) === "commerce_option_A" || ((ref2 = getLastLyceeStudyYear(object)) === null || ref2 === void 0 ? void 0 : ref2.filiereBacPro) === "commerce_option_B" ? true : false;
};
export var isGatl = function(object) {
    var ref, ref1, ref2;
    return ((ref = getLastLyceeStudyYear(object)) === null || ref === void 0 ? void 0 : ref.filiereBacPro) === "assistance_gestion_organisations" || ((ref1 = getLastLyceeStudyYear(object)) === null || ref1 === void 0 ? void 0 : ref1.filiereBacPro) === "logistique" || ((ref2 = getLastLyceeStudyYear(object)) === null || ref2 === void 0 ? void 0 : ref2.filiereBacPro) === "transport" ? true : false;
};
