import {
  ACTIVITE_CONTENT_TYPE_VIDEO,
  ACTIVITE_CONTENT_TYPE_FORM,
  ACTIVITE_CONTENT_TYPE_GAME,
  ACTIVITE_CONTENT_TYPE_ORAL,
} from '@inspire/data/static/activites';

import {
  // TemoignageVideo,
  MesCentresDinterets,
  MesPreferencesDapprentissage,
  MesPrioritesPourMonAvenir,
  MesMatieresPreferees,
  QualitesForm,
  MetierOuSecteur,
} from 'components/activites/seconde/mieux-se-connaitre/japprends-a-me-connaitre';
import {
  IdentifierSoftSkillInstructions,
  IdentifierSoftSkill2,
  IdentifierSoftSkill3,
} from 'components/activites/seconde/decouvrir-les-soft-skills/IdentifierSoftSkills';
import RaconteToiGroupe from 'components/activites/seconde/decouvrir-les-soft-skills/RaconteToiGroupe';
import { ChatbotConclusion } from 'components/activites/seconde/mes-soft-skills/Chatbot';
import LanguageProIcon from '@inspire/ui/assets/undraw/work_chat.svg';
import SecteurAgroIcon from '@inspire/ui/assets/undraw/apprentissage.svg';
import SePositionnerIcon from '@inspire/ui/assets/undraw/se_positionner.svg';
import RechercheStageIcon from '@inspire/ui/assets/undraw/job_hunt.svg';
import DeclicIcon from '@inspire/ui/assets/undraw/declic_soft_skills.svg';
import {
  ChecklistCV,
  QuizzCV,
} from 'components/activites/seconde/rechercher-son-stage-et-postuler/cv';
import {
  ChecklistLM,
  QuizzLM,
} from 'components/activites/seconde/rechercher-son-stage-et-postuler/lm';
import LanguageProVideoTemoignage from 'components/activites/seconde/bons-reflexes-pro/LanguageProVideoTemoignage';
import LanguageProCasPratiques from 'components/activites/seconde/bons-reflexes-pro/LanguageProCasPratiques';
import LanguageProRecap from 'components/activites/seconde/bons-reflexes-pro/LanguageProRecap';
import {
  SoftSkillsDefinition,
  SoftSkillsReflexion,
} from 'components/activites/seconde/decouvrir-les-soft-skills/soft-skills-et-referentiel';
import {
  DeclicExperience,
  DeclicListCompetences,
} from 'components/activites/seconde/decouvrir-les-soft-skills/experience-et-competences';
import {
  QCMRechercheDeStage,
  QuestionsRechercheDeStage,
  VideoRechercheDeStage,
  PreparerRechercheDeStage,
} from 'components/activites/seconde/rechercher-son-stage-et-postuler/rechercher-sur-internet';
import SoftSkillsRecruteurs from 'components/activites/seconde/decouvrir-les-soft-skills/SoftSkillsRecruteurs';
import { makeBilanActivite } from 'lib/activites';
import {
  AvantagesVoieProfessionnelle,
  SiTaFormationEtaitUneRoute,
} from 'components/activites/seconde/mieux-se-connaitre/je-fais-le-point';
import {
  AdequationCentresInteretsPriorites,
  AdequationMetierSecteurReve,
  AdequationPreferencesApprentissage,
  AdequationQualites,
  MaFormationActuelle,
  MesPointsFortsScolaires,
} from 'components/activites/seconde/mieux-se-connaitre/mon-parcours-scolaire';
import {
  CommentFaireDeSaPassionUnMetier,
  CommentFaireDeSonParcoursUneForce,
  CommentTrouverSaVoie,
} from 'components/activites/seconde/mieux-se-connaitre/parcours-inspirants';
import {
  SecteurAgricoleEn3Mots,
  SecteurAgricoleEn3IdeesRecues,
  VraiFauxMondeAgro,
  SecteurAgro4Chiffres,
  EnjeuxEtSecteursAvenir,
  CategoriesDefis,
} from 'components/activites/seconde/decouvrir-le-secteur-agro/je-me-repere-et-je-minforme';
import { MetierQuiADuSens } from 'components/activites/seconde/decouvrir-le-secteur-agro/je-minspire-et-midentifie';
import {
  InsertionProOuPoursuiteDetudes,
  PourquoiPoursuivreSesEtudesOuSinsererProfessionnellement,
  VerifierLesSecteursAvenir,
} from 'components/activites/seconde/decouvrir-le-secteur-agro/je-me-projette-et-je-me-positionne';

// const hasFinishedQuestionnaire = {
//   condition: ({ myBacProActivites }) =>
//     myBacProActivites?.find(
//       (a) => a.activiteId === 'mon-parcours' && a.status === 'completed'
//     ),
//   reason:
//     'Tu dois compléter le module "Mieux se connaitre" avant de débuter ce module.',
//   profReason:
//     'Vos élèves doivent compléter le module "Mieux se connaitre" pour débloquer ce module.',
// };

const hasFinishedActiviteJapprendsMeConnaitre = {
  condition: ({ myBacProActivites }) =>
    myBacProActivites?.find(
      (a) =>
        a.activiteId === 'japprends-a-me-connaitre' && a.status === 'completed'
    ),
  reason:
    "Pour commencer cette activité, tu dois d'abord réaliser l'activité \"J'apprends à me connaître\".",
  profReason:
    "Pour commencer cette activité, vos élèves doivent d'abord réaliser l'activité \"J'apprends à me connaître\".",
};

const hasFinishedActiviteMonParcoursScolaire = {
  condition: ({ myBacProActivites }) =>
    myBacProActivites?.find(
      (a) =>
        a.activiteId === 'mon-parcours-scolaire' && a.status === 'completed'
    ),
  reason:
    'Pour commencer cette activité, tu dois d\'abord réaliser l\'activité "Mon parcours scolaire".',
  profReason:
    'Pour commencer cette activité, vos élèves doivent d\'abord réaliser l\'activité "Mon parcours scolaire".',
};

export const MIEUX_SE_CONNAITRE_ID = 'mieux-se-connaitre';
const DECOUVRIR_SOFT_SKILLS_ID = 'decouvrir-les-soft-skills';
const RECHERCHER_STAGE_POSTULER_ID = 'rechercher-son-stage-et-postuler';
const DECOUVRIR_CODES_PRO_ID = 'decouvrir-les-codes-pro';
export const DECOUVRIR_SECTEUR_AGRO_ID = 'decouvrir-le-secteur-agro';

const modules = [
  {
    theme: 'construire-mon-projet-dorientation',
    moduleId: MIEUX_SE_CONNAITRE_ID,
    title: 'Mieux se connaître',
    icon: SePositionnerIcon,
    intro:
      'Ce module t’aidera à faire le point sur ta formation pour commencer à réfléchir à ton projet d’orientation scolaire et professionnelle.',
    profDescription:
      'Ce module vous aidera à accompagner l’élève dans son projet d’orientation scolaire et professionnelle en identifiant ses besoins et ses acquis.',
    isMandatory: true,
    activites: [
      {
        title: 'J’apprends à me connaitre',
        activiteId: 'japprends-a-me-connaitre',
        description:
          'Tes rêves, tes préférences et tes qualités peuvent t’aider à choisir ton orientation. Aussi, nous te proposons de répondre à ce questionnaire qui t’aidera à faire le point.',
        profDescription:
          'Via un questionnaire, les élèves peuvent faire le point sur leurs centres d\'intérêts, préférences d’apprentissage, secteurs professionnels visés, goût pour les matières (activité à réaliser avant l’activité 2 "Mon parcours scolaire")',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM }],
        duration: 10,
        steps: [
          // {
          //   title: 'Faire de mon parcours une force',
          //   component: TemoignageVideo,
          // },
          {
            title: 'Mes centres d’intérêts',
            component: MesCentresDinterets,
            hasOwnFooter: true,
          },
          {
            title: 'Mes préférences d’apprentissage',
            component: MesPreferencesDapprentissage,
            hasOwnFooter: true,
          },
          {
            title: 'Mes priorités pour mon avenir',
            component: MesPrioritesPourMonAvenir,
            hasOwnFooter: true,
          },
          {
            title: 'Mes matières préférées',
            component: MesMatieresPreferees,
            hasOwnFooter: true,
          },
          {
            title: 'Mes qualités',
            component: QualitesForm,
            hasOwnFooter: true,
          },
          {
            title: 'Mon métier ou secteur de rêve',
            component: MetierOuSecteur,
            hasOwnFooter: true,
          },
        ],
      },
      {
        title: 'Mon parcours scolaire',
        activiteId: 'mon-parcours-scolaire',
        description:
          'Ce questionnaire te permet de faire le point sur ton parcours scolaire et la manière dont tu perçois ta formation actuelle.',
        profDescription:
          'Via un questionnaire, les élèves peuvent faire le point sur leur parcours scolaire et la manière dont ils perçoivent leur formation actuelle.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM }],
        duration: 10,
        requirements: [hasFinishedActiviteJapprendsMeConnaitre],
        steps: [
          {
            title: 'Ma formation actuelle',
            component: MaFormationActuelle,
            hasOwnFooter: true,
          },
          {
            title: 'Adéquation avec mes préférences d’apprentissage',
            component: AdequationPreferencesApprentissage,
            hasOwnFooter: true,
          },
          {
            title: 'Adéquation avec mon métier ou secteur de rêve',
            component: AdequationMetierSecteurReve,
            hasOwnFooter: true,
          },
          {
            title: "Adéquation avec mes centres d'intérêts et priorités",
            component: AdequationCentresInteretsPriorites,
            hasOwnFooter: true,
          },
          {
            title: 'Adéquation avec mes qualités',
            component: AdequationQualites,
            hasOwnFooter: true,
          },
          {
            title: 'Mes points forts scolaires',
            component: MesPointsFortsScolaires,
            hasOwnFooter: true,
          },
        ],
      },
      {
        title: 'Je fais le point',
        activiteId: 'je-fais-le-point',
        description:
          "Cette activité te permettra de faire le point sur la manière dont tu vois ta formation et les possibilités qu'elle t'offre",
        profDescription:
          "Cette activité permettra à l'élève de faire le point sur sa vision de sa formation",
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_FORM },
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
        ],
        duration: 10,
        requirements: [hasFinishedActiviteMonParcoursScolaire],
        steps: [
          {
            title: 'Si ta formation était une route ...',
            component: SiTaFormationEtaitUneRoute,
            hasOwnFooter: true,
          },
          {
            title: 'Les avantages de la voie professionnelle',
            component: AvantagesVoieProfessionnelle,
          },
        ],
      },
      {
        title: 'Parcours inspirants',
        activiteId: 'parcours-inspirants',
        description:
          'Cette activité te permettra de découvrir les témoignages de personnes qui ont choisi ou non leurs formations et qui ont réussi à faire de leur parcours une force.',
        profDescription:
          'Cette activité  permettra à l’élève de découvrir les témoignages de personnes qui ont choisi ou non leurs formations et qui ont réussi à faire de leur parcours une force.',
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
        ],
        duration: 20,
        requirements: [hasFinishedActiviteMonParcoursScolaire],
        steps: [
          {
            title: 'Comment faire de sa passion un métier ?',
            component: CommentFaireDeSaPassionUnMetier,
          },
          {
            title: 'Comment faire de son parcours une force ?',
            component: CommentFaireDeSonParcoursUneForce,
          },
          {
            title: 'Comment trouver sa voie ?',
            component: CommentTrouverSaVoie,
          },
        ],
      },
      makeBilanActivite(MIEUX_SE_CONNAITRE_ID),
    ],
    bilanQuestions: [
      { id: 'citer-qualites', text: 'Je peux citer mes qualités.' },
      {
        id: 'citer-priorites-futur-metier',
        text: 'Je peux citer mes priorités dans le choix de mon futur métier.',
      },
      {
        id: 'vision-plus-claire',
        text: 'J’ai une vision plus claire de ce que j’aimerais faire plus tard.',
      },
    ],
  },
  {
    theme: 'construire-mon-projet-dorientation',
    moduleId: DECOUVRIR_SECTEUR_AGRO_ID,
    title: 'Découvrir le secteur agricole',
    icon: SecteurAgroIcon,
    intro:
      'Ce module t’aidera à prendre connaissance des évolutions du secteur agricole, et à commencer à réfléchir à tes envies pour ton orientation dans ce secteur. ',
    profDescription:
      'Ce module vous aidera à accompagner le travail de réflexion autour de l’orientation et la poursuite d’études dans le secteur agricole à travers le développement de l’esprit critique et la découverte du secteur dans son ensemble. ',
    activites: [
      {
        title: 'Je me repère et je m’informe',
        activiteId: 'je-me-repere-et-je-minforme',
        description:
          'Cette activité te permet de découvrir le secteur agricole et ses enjeux',
        profDescription:
          'L’activité à pour but de déconstruire les idées reçues sur le secteur agricole et comprendre ses enjeux.',
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_FORM },
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
        ],
        duration: 40,
        steps: [
          {
            title: 'Le secteur agricole en 3 mots',
            component: SecteurAgricoleEn3Mots,
            hasOwnFooter: true,
          },
          {
            title: 'Le secteur agricole en 3 idées reçues',
            component: SecteurAgricoleEn3IdeesRecues,
          },
          {
            title: 'Vrai / Faux sur le monde agricole',
            component: VraiFauxMondeAgro,
          },
          {
            title: 'Le secteur agricole en 4 chiffres',
            component: SecteurAgro4Chiffres,
          },
          {
            title: 'Les enjeux et secteurs d’avenir',
            component: EnjeuxEtSecteursAvenir,
          },
          {
            title: 'À chaque catégorie ses défis !',
            component: CategoriesDefis,
          },
        ],
      },
      {
        title: 'Je m’inspire et m’identifie',
        activiteId: 'je-minspire-et-midentifie',
        description:
          'À travers cette activité, découvre les parcours d’orientation et d’études de personnes qui ont évolué dans le secteur agricole',
        profDescription:
          'Cette activité a pour but d’inspirer les élèves et à les encourager à la poursuite d’études via des témoignages de personnes ayant évolué dans l’enseignement et le secteur agricole. ',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_VIDEO }],
        duration: 10,
        steps: [
          {
            title: 'Trouver un métier qui a du sens',
            component: MetierQuiADuSens,
          },
        ],
      },
      {
        title: 'Je me projette et je me positionne',
        activiteId: 'je-me-projette-et-je-me-positionne',
        description:
          'Cette activité a pour but de t’aider à commencer à réfléchir à tes envies d’orientation dans le secteur agricole.',
        profDescription:
          'Cette activité a pour but de commencer à identifier des leviers de motivations à la poursuite d’études ou à l’insertion professionnelle, et à se projeter dans les filières du secteur agricole.',
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
          { type: ACTIVITE_CONTENT_TYPE_GAME },
        ],
        duration: 20,
        steps: [
          {
            title: 'Insertion pro ou poursuite d’études ?',
            component: InsertionProOuPoursuiteDetudes,
            hasOwnFooter: true,
          },
          {
            title:
              'Pourquoi poursuivre ses études ou s’insérer professionnellement',
            component: PourquoiPoursuivreSesEtudesOuSinsererProfessionnellement,
            hasOwnFooter: true,
          },
          {
            title: 'Vérifier les secteurs d’avenir',
            component: VerifierLesSecteursAvenir,
          },
        ],
      },
      makeBilanActivite(DECOUVRIR_SECTEUR_AGRO_ID),
    ],
    bilanQuestions: [
      {
        id: 'comprendre-fonctionnement-secteur-agricole',
        text: 'Je comprends mieux le fonctionnement du secteur agricole',
      },
      {
        id: 'projetter-l’insertion-professionnelle-ou-poursuite-etudes',
        text: 'Je me projette dans l’insertion professionnelle ou la poursuite d’études',
      },
      {
        id: 'projetter-categrotie-metiers-secteur-agricole',
        text: 'Je me projette dans une catégorie de métiers du secteur agricole',
      },
    ],
  },
  {
    theme: 'insertion-pro',
    moduleId: DECOUVRIR_SOFT_SKILLS_ID,
    title: 'Découvrir les compétences transversales',
    icon: DeclicIcon,
    intro:
      "L'objectif de ce module est de t’aider à comprendre la notion de compétences transversales et te permettre d’identifier celles que tu as acquises au cours de ton parcours personnel et durant tes études afin de les valoriser.",
    profDescription:
      'Ce module a pour objectif d’introduire la notion de soft skills / compétences transversales comme un élément de valorisation des parcours des lycéens.',
    objectifPedagogique:
      'Avec ce module, les élèves comprennent ce que signifie les soft skills / compétences transversales et prennent conscience que tous en possèdent et qu’elles sont valorisées par les recruteurs.',
    activites: [
      {
        title: "Qu'est-ce que les soft skills ?",
        activiteId: 'qu-est-ce-les-soft-skills',
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
          { type: ACTIVITE_CONTENT_TYPE_GAME },
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
        ],
        duration: 20,
        description:
          'Cette activité te permet de découvrir la notion de soft skills / compétences transversales et son importance.',
        profDescription:
          'L’activité a pour but de permettre à l’élève de découvrir et de se familiariser avec la notion de soft skills / compétences transversales et de comprendre leur importance pour les recruteurs.',
        steps: [
          {
            title: 'Identifier et catégoriser les soft skills',
            component: IdentifierSoftSkillInstructions,
          },
          // { title: 'Extrait vidéo 1', component: IdentifierSoftSkill1 },
          { title: 'Extrait vidéo 1', component: IdentifierSoftSkill2 },
          { title: 'Extrait vidéo 2', component: IdentifierSoftSkill3 },
          {
            title: 'Définition des soft skills',
            component: SoftSkillsDefinition,
          },
          {
            title: 'Soft skills et réferentiel',
            component: SoftSkillsReflexion,
          },
          {
            title: 'L’importance des soft skills pour les recruteurs',
            component: SoftSkillsRecruteurs,
          },
        ],
      },
      {
        title: 'Mes soft skills',
        activiteId: 'mes-soft-skills',
        duration: 20,
        description:
          'Tout le monde possède des soft skills / compétences transversales, cette activité te permet d’identifier celles que tu as acquises à travers tes expériences.',
        profDescription:
          'L’activité permet à l’élève de s’approprier la notion de soft skills / compétences transversales au travers d’expériences personnelles.',
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_FORM },
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
        ],
        steps: [
          {
            title: 'Raconte-toi : Expérience et compétences',
            component: DeclicExperience,
            hasOwnFooter: true,
          },
          {
            title: 'Raconte-toi : Expérience et compétences',
            component: DeclicListCompetences,
            hasOwnFooter: true,
          },
          { title: 'Raconte-toi en groupe', component: RaconteToiGroupe },
          {
            title: 'Conclusion : Témoignages sur les soft skills',
            component: ChatbotConclusion,
          },
        ],
      },
      makeBilanActivite(DECOUVRIR_SOFT_SKILLS_ID),
    ],
    bilanQuestions: [
      {
        id: 'definir-soft-skills',
        text: 'Je peux définir les soft skills / compétences transversales.',
      },
      {
        id: 'importance-soft-skills',
        text: 'Je comprends pourquoi les soft skills / compétences transversales sont importantes pour les recruteurs.',
      },
      {
        id: 'citer-un-soft-skill',
        text: 'Je peux citer une de mes soft skills / compétences transversales.',
      },
    ],
  },
  {
    theme: 'insertion-pro',
    moduleId: RECHERCHER_STAGE_POSTULER_ID,
    title: 'Rechercher son stage et postuler',
    icon: RechercheStageIcon,
    intro:
      "L'objectif de ce module est de t’aider à savoir comment rechercher une offre de stage sur Internet ainsi qu’y répondre avec la rédaction d’un CV et d’une LM.",
    profDescription:
      'Ce module a pour objectif d’aider le lycéen à savoir comment chercher une offre de stage sur Internet et savoir y répondre avec l’élaboration d’un CV et d’une lettre de motivation.',
    objectifPedagogique:
      'Avec ce module, les lycéens seront en capacité d’initier une recherche de stage sur Internet et d’y postuler.',
    activites: [
      {
        title: 'Je recherche mon stage sur Internet',
        activiteId: 'recherche-stage-sur-internet',
        duration: 20,
        description:
          'En regardant une vidéo, tu découvriras comment faire une recherche de stage sur Internet.',
        profDescription:
          'À travers une vidéo, cette activité propose une méthode de recherche de stage sur Internet.',
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
          { type: ACTIVITE_CONTENT_TYPE_FORM },
        ],
        steps: [
          {
            title: 'Faire une recherche de stage efficace sur Internet',
            component: VideoRechercheDeStage,
          },
          {
            title: 'Questions Rechercher son stage sur Internet',
            component: QuestionsRechercheDeStage,
            hasOwnFooter: true,
          },
          {
            title: 'Préparer sa recherche de stage',
            component: PreparerRechercheDeStage,
          },
          {
            title: 'QCM',
            component: QCMRechercheDeStage,
          },
        ],
      },
      {
        title: 'Conseils pour mon CV',
        activiteId: 'conseils-cv',
        description:
          'Cette activité te permet de tester tes connaissances sur la rédaction d’un CV et d’avoir quelques conseils. ',
        profDescription:
          'Cette activité permet aux élèves de tester leurs connaissances sur la rédaction d’un CV et d’avoir quelques conseils.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM }],
        duration: 15,
        steps: [
          { title: 'Quiz sur le CV', component: QuizzCV },
          {
            title: 'Checklist : CV',
            component: ChecklistCV,
            hasOwnFooter: true,
          },
        ],
      },
      {
        title: 'Conseils pour ma lettre de motivation',
        activiteId: 'conseils-lettre-motivation',
        description:
          'cette activité te permet de tester tes connaissances sur la rédaction d’une lettre de motivation et d’avoir quelques conseils.',
        profDescription:
          'Cette activité permet aux élèves de tester leurs connaissances sur la rédaction d’une lettre de motivation et d’avoir quelques conseils.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM }],
        duration: 15,
        steps: [
          { title: 'Quiz sur la lettre de motivation', component: QuizzLM },
          {
            title: 'Checklist : Lettre de motivation',
            component: ChecklistLM,
            hasOwnFooter: true,
          },
        ],
      },
      makeBilanActivite(RECHERCHER_STAGE_POSTULER_ID),
    ],
    bilanQuestions: [
      {
        id: 'capable-recherche-stage-internet',
        text: 'Je me sens capable de faire une recherche de stage sur internet.',
      },
      {
        id: 'capable-faire-cv',
        text: 'Je me sens capable de faire un CV.',
      },
      {
        id: 'capable-faire-lm',
        text: 'Je me sens capable de faire une lettre de motivation.',
      },
    ],
  },
  {
    theme: 'insertion-pro',
    moduleId: DECOUVRIR_CODES_PRO_ID,
    title: 'Découvrir les codes professionnels',
    icon: LanguageProIcon,
    intro:
      "L'objectif de ce module est de t’aider à savoir comment tu dois te comporter ou t’exprimer au sein de l’entreprise où tu effectueras ton stage.",
    profDescription:
      'Ce module a pour objectif d’aider le lycéen à connaître les codes de l’entreprise, les expressions à retenir et comment y graviter.',
    objectifPedagogique:
      'Permettre aux lycéens de comprendre l’organisation pratique d’une entreprise et quelques normes et comportements à adopter lors des stages.',
    activites: [
      {
        title: "J'adopte les bons reflexes professionnels",
        activiteId: 'bons-reflexes-pro',
        description:
          'Quelques conseils pratiques pour savoir comment se comporter pendant ta période de stage.',
        profDescription:
          'L’activité a pour but de transmettre à l’élève des informations pratiques pour se familiariser avec l’univers de l’entreprise et comment s’y comporter durant sa période de stage.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_VIDEO, count: 1 }],
        duration: 35,
        steps: [
          {
            title: 'Cas pratiques',
            component: LanguageProCasPratiques,
          },
          {
            title: 'Vidéo témoignage',
            component: LanguageProVideoTemoignage,
          },
          {
            title: 'Language professionnel (infographie)',
            component: LanguageProRecap,
          },
        ],
      },
      makeBilanActivite(DECOUVRIR_CODES_PRO_ID),
    ],
    bilanQuestions: [
      {
        id: 'citer-regles-comportement-pro',
        text: "Je peux citer quelques règles fondamentales d'un comportement professionnel en stage.",
      },
      {
        id: 'capable-comportement-pro',
        text: "Je me sens capable d'avoir un comportement professionnel en stage.",
      },
    ],
  },
];

export default modules;
